.navbar {
  @include flex(space-between, flex-end);
  height: fit-content;
  padding: 8px 128px;
  background: $dark-gray;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  .tabs {
    @include flex;
    gap: 24px;
    height: fit-content;

    a {
      padding: 4px 8px;
      &:hover {
        h2 {
          color: $primary;
        }
      }
    }
    .active {
      h2 {
        border-bottom: 2px solid $primary;
      }
    }
  }
}

.burgir {
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: fixed;
  margin: 8px 16px;
  z-index: 2;
  path {
    fill: $primary;
  }
}

.navbarColumn {
  @include flex(space-between);
  flex-direction: column;
  width: fit-content;
  background: $dark-gray;
  padding: 64px 8px 16px 8px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  transition: 100ms;
  .top {
    text-align: center;
    width: 100%;
    svg {
      width: 75%;

      height: auto;
    }
  }
  .tabs {
    @include flex;
    flex-direction: column;
    margin-top: 128px;
    gap: 4px;
    width: 100%;
    a {
      border-bottom: 2px solid $black;
      padding: 4px 0;
      width: 100%;
      text-align: center;
      &:hover {
        h3 {
          color: $primary;
        }
      }
      h3 {
        color: $gray;
      }
      &:last-child {
        border: none;
      }
    }
    .active {
      h2 {
        border-bottom: 2px solid $primary;
        // color: $primary;
      }
      h3 {
        color: $primary;
      }
    }
  }
  .bottom {
    @include flex;
    flex-direction: column;
    gap: 8px;
    .capsule {
      background-color: $gray;
    }
    .icons {
      justify-self: flex-end;
      cursor: pointer;
      a {
        svg {
          &:hover {
            path {
              fill: $primary;
            }
          }
        }
        path {
          fill: $gray;
        }
      }
    }
  }
}

.navEnter {
  transition: 200ms;
  left: 0px !important;
  width: 128px;
  padding-top: 96px;
  a {
    text-align: start !important;
    padding-left: 8px !important;
  }
}
