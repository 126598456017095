button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;
  font: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.btn {
  @include bcb($primary, $black, $primary);
  width: fit-content;
  padding: 6px 18px 4px 18px;
  background: linear-gradient($primary 50%, $black 50%);
  background-size: 100% 200%;
  transition: 100ms;
  &:hover {
    background-position: 0% 100%;
    transition: 200ms;
    color: $primary;
  }
}

.btn-scroll {
  z-index: 2;
  @include flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  right: 50%;
  width: 72px;
  height: 72px;
  animation: hover-small infinite 2s, glow-small infinite 2s;
  border-radius: 50%;
  margin: 32px 0;
  margin-left: -36px;
  cursor: pointer;
  &:hover {
    animation: hover infinite 2s, glow infinite 2s;
  }
  svg {
    width: 32px;
    transform: rotate(90deg) translateX(4px);
    .cls-1 {
      fill: $primary;
    }
    .cls-2 {
      fill: $orange;
    }
  }
  .hex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: none;

    polygon {
      fill: $white !important;
      stroke: $primary !important;
      strokewidth: 14px !important;
    }
  }
}

.arrow {
  cursor: pointer;
  @include flex;
  path {
    fill: $primary;
  }
}

.on {
  flex-direction: row-reverse;
  svg {
    transition: 0ms;
    transform: translateX(0);
    opacity: 1;
  }
  .toggle {
    transition: 0ms;
    transform: translateX(0);
    opacity: 1;
  }
}
