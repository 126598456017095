.cards {
  @include flex;
  gap: 64px;
  margin: 0 auto;
  flex-wrap: wrap;
  width: 100%;
}

.cardsBig {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $laptop) {
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    max-width: 100%;
    flex: 1;
    button {
      height: 300px;
    }
    h1 {
      color: $white;
    }
    .langs {
      h2 {
        color: $primary;
      }
    }
  }
}

.card {
  @include flex;

  align-self: flex-start;
  flex-direction: column;
  position: relative;
  box-shadow: $shadow;
  max-width: 320px;
  width: 100%;
  background: $dark-gray;
  transition: 200ms;

  button {
    height: 192px;
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 200ms;
  }
  .info {
    @include flex(space-between, stretch);
    flex-direction: column;
    text-align: center;
    background-color: $dark-gray;
    width: 100%;
    .header {
      @include flex(space-between);
      @include bcb($black, $white, $primary);
      border-left: none;
      border-right: none;
      border-top: none;
      padding: 8px 8px 2px 8px;

      path {
        fill: $black;
      }
    }
    .langs {
      @include flex(flex-start, flex-start);
      max-height: 48px;
      flex-wrap: wrap;
      margin: 12px 10px;
      margin-bottom: 0;
      gap: 2px;
      row-gap: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // h3 {
      //   font-size: 24px;
      // }
      p {
        @include flex(center, flex-end);
        gap: 2px;
        color: $gray;
        padding: 0 4px;
        max-height: 22px;
        width: fit-content;
        svg {
          max-height: 100%;
          color: $gray;
        }
        &:hover {
          color: $primary;
          transform: scale(1.01);
        }
      }
    }
  }
}

.card-show {
  @include flex;
  flex-direction: row;
  position: relative;
  width: fit-content;
  max-width: 100%;
  min-height: 420px;
  height: 100%;
  z-index: 3;
  transition: 500ms;
  margin-top: -1000px;
  @media (max-width: $mobile) {
    flex-direction: column;
    height: 100%;
    min-height: fit-content;
    .image-wrapper,
    .info {
      flex-grow: 1 !important;
      height: 100% !important;
      min-height: unset !important;
    }
  }
  .image-wrapper {
    @include flex(center);
    position: relative;
    overflow: hidden;
    min-width: 420px;

    max-width: 100%;
    height: 100%;

    .images {
      transition: 500ms;
      @include flex(flex-start);
      width: fit-content;
      min-height: 100%;
      height: 100%;
      img {
        max-height: 100%;
        height: 100%;
        min-width: 100%;
        max-width: 100%;
        object-fit: scale-down;
      }
      video {
        max-height: 100%;
        height: 100%;
        min-width: 100%;
        max-width: 100%;
        object-fit: scale-down;
      }
      iframe {
        max-height: 100%;
        height: 100%;
        min-width: 100%;
        max-width: 100%;
        object-fit: scale-down;
      }
    }
    .small-arrows {
      @include flex(space-between);
      position: absolute;
      width: 100%;
      height: fit-content;
      z-index: 4;
      #right {
        right: 0;
        padding-left: 48px;
        .arrow {
          background: linear-gradient(90deg, $primary 50%, $black 50%);
          background-size: 200% 100%;
          background-position: 100% 0%;
          &:hover {
            background-position: 0% 0%;
            transition: 200ms;
            path {
              fill: $black;
            }
          }
        }
        path {
          transform: rotate(90deg);
          transform-origin: 50% 50%;
        }
      }
      #left {
        left: 0;
        padding-right: 48px;
        path {
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
        }
      }
      .arrow-wrapper {
        position: absolute;
        @include flex(center, space-between);
        flex-direction: column;
        height: 256px;
        cursor: pointer;
        border: none;
        .arrow {
          @include flex;
          padding: 8px 6px 8px 4px;
          background: linear-gradient(90deg, $black 50%, $primary 50%);
          background-size: 200% 100%;
          transition: 100ms;
          path {
            fill: $primary;
          }
          &:hover {
            background-position: 100% 0%;
            transition: 200ms;
            path {
              fill: $black;
            }
          }
        }
      }
    }
    .markers {
      @include flex;
      z-index: 4;
      gap: 8px;
      position: absolute;
      bottom: 0;
      transform-origin: 50% 50%;
      max-width: fit-content;
      width: fit-content;
      min-height: fit-content;
      padding: 10px 12px 8px 12px;
      background: $black;
      .marker {
        width: 14px;
        aspect-ratio: 1;
        opacity: 0.6;
        filter: grayscale(1);
        cursor: pointer;
        &:hover {
          filter: grayscale(0.5);
        }
      }
      .video-marker {
        width: 20px;
        height: 20px;
        path {
          fill: $primary;
        }
      }
      .active {
        opacity: 1;
        filter: none;
        &:hover {
          filter: none;
        }
      }
      .cls-1 {
        fill: $primary;
        stroke: $primary;
        strokewidth: 2;
      }
      .cls-2 {
        fill: $orange;
        stroke: $orange;
        strokewidth: 2;
      }
      .cls-3 {
        fill: $yellow;
        stroke: $yellow;
        strokewidth: 2;
      }
    }
  }

  .info {
    min-width: 320px;
    max-width: 400px;
    height: 100%;
    .header {
      padding: 2px;
      padding-top: 8px;
      path {
        fill: $primary;
      }
      .exit {
        cursor: pointer;
        position: absolute;
        right: 0;
        padding-bottom: 6px;
        padding-right: 8px;
      }
    }
    .desc {
      background-color: $white;
      border-bottom: 2px solid $primary;
      height: 100%;
      padding: 8px 16px;
      color: $gray;
      text-align: justify;
      hyphens: auto;
    }
    .langs {
      overflow: visible;
      height: fit-content;
    }
  }
}

.module {
  visibility: hidden;
  position: fixed;
  @include flex;
  flex-direction: column;

  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 0;
  z-index: 10;
  transition: 500ms;
  padding: 64px;
  .overlay {
    background: rgba(black, 0.9);
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .big-arrow {
    position: relative;
    width: 64px;
    height: 64px;
    margin: 16px;
    transform: rotate(90deg);
    cursor: pointer;
    z-index: 8;

    &:first-child {
      transform: rotate(-90deg);
    }

    &:hover {
      @include up-and-down;
    }

    .cls-1 {
      fill: $primary;
      stroke: $primary;
    }
    .cls-2 {
      fill: $yellow;
      stroke: $yellow;
    }
  }
}

.module-enter {
  visibility: visible;
  max-height: inherit;
  opacity: 1;
}

.card-enter {
  margin-top: 0;
  transition: 500ms;
}
