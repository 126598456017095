#origami {
  .cls-1 {
    fill: #feb701;
  }
  .cls-2 {
    fill: #fecc00;
  }
  .cls-3 {
    fill: #ffa300;
  }
  .cls-4 {
    fill: #fe8c00;
  }
}

#digital {
  .cls-1 {
    fill: #f46c01;
  }
  .cls-2 {
    fill: #ffa300;
  }
  .cls-3 {
    fill: #fe8c00;
  }
  .cls-4 {
    fill: #fecc00;
  }
  .cls-5 {
    fill: #feb701;
  }
}

#draw {
  .cls-1 {
    fill: #fe8c00;
  }
  .cls-2 {
    fill: #ffa300;
  }
  .cls-3 {
    fill: #feb701;
  }
  .cls-4 {
    fill: #f46c01;
  }
  .cls-5 {
    fill: #fecc00;
  }
}

#paint {
  .cls-1 {
    fill: #feb701;
  }
  .cls-2 {
    fill: #f46c01;
  }
  .cls-3 {
    fill: #fe8c00;
  }
  .cls-4 {
    fill: #ffa300;
  }
  .cls-5 {
    fill: #fecc00;
  }
}
