.banner {
  margin: 0 auto;
  max-width: fit-content;
  @include flex;
  flex-wrap: wrap-reverse;
  gap: 32px;
  height: 100%;
  .text {
    // margin-left: 64px;
    text-align: start;
    h1 {
      width: fit-content;
    }
    h2 {
      margin: 0;
      color: $gray;
      width: fit-content;
    }
    span {
      font-size: 30px;
      padding: 0 8px;
      color: $white;
    }
    .phrase {
      color: $white;
    }
  }
  .icons {
    @include flex(flex-start);
    margin-top: 8px;
    gap: 8px;
    svg {
      width: 32px;
      height: 32px;
      path {
        fill: $primary;
      }
    }
  }

  .hex-holder {
    max-width: 320px;
    min-width: auto;
    position: relative;

    .flying {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.15;
      polygon {
        stroke: $primary !important;
        strokewidth: 6px !important;
      }
    }
    .blur {
      @include pulsesvg;
      opacity: 1;
      z-index: -2;
      polygon {
        fill: $primary !important;
      }
    }
    .expand {
      animation: 6s infinite expand;
    }
    .one {
      animation-delay: 1s;
    }
    .two {
      animation-delay: 2s;
    }
    .three {
      animation-delay: 3s;
    }
    svg {
      width: 100%;
      image {
        x: -100;
        y: -60;
        transform: scale(1.05);
      }
      polygon {
        stroke: $primary !important;
        strokewidth: 6px !important;
      }
    }
  }
}
