@import "../../style/mixins.scss";

$trans: 200ms;

.capsule {
  @include flex;
  background: $white;
  width: fit-content;
  flex-direction: row;
  transition: $trans;
  gap: 6px;
  height: 22px;
  border-radius: 32px;
  padding: 2px;
  margin-bottom: 2px;
  cursor: pointer;
  &:hover {
    background: $primary !important;
  }
  .toggle {
    transition: $trans;
    opacity: 1;
    border-radius: 50%;
    background: $dark-gray;
    height: 100%;
    aspect-ratio: 1;
  }
  svg {
    transition: $trans;
    opacity: 1;
    width: 100%;
    height: 100%;
    path {
      fill: $dark-gray;
    }
  }
}

.toggle-transition {
  svg {
    transition: $trans;
    transform: translateX(-20px);
    opacity: 0;
  }
  .toggle {
    transition: $trans;
    transform: translateX(20px);
    opacity: 0;
  }
}
