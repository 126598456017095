.art {
  @include flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
  .categories {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 32px;
    max-width: 75%;
    width: 100%;
    @media (max-width: $mobile) {
      grid-template-columns: 1fr 1fr;
    }
    .category {
      cursor: pointer;
      .box {
        @include flex;
        width: 100%;
        aspect-ratio: 1;
        background: $white;
        border: 2px solid $primary;
        padding: 0;
        &:hover {
          background: linear-gradient(120deg, $yellow, $orange);
          background-size: 400% 400%;
          background-position: 0% 0%;
          animation: grad 3s ease-in-out infinite;
        }
      }
      h3 {
        width: 100%;
        background-color: $black;
        border-bottom: 2px solid $primary;
        padding: 8px 2px 6px 2px;
      }

      svg {
        aspect-ratio: 1;
        max-width: 128px;
        padding: 16px;
      }
    }
  }

  .cards {
    gap: 16px;
  }
}

@keyframes grad {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.card-lil {
  position: relative;
  width: 192px;
  height: 192px;
  background-color: $dark-gray;
  cursor: pointer;
  filter: grayscale(0);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    filter: grayscale(1);
    transition: 200ms;
    h3 {
      opacity: 1;
    }
    &:before {
      opacity: 0.8;
    }
  }
  h3 {
    color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0;
  }
  &:before {
    content: "";
    position: absolute;
    background: black;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
}
