@import "../../style/mixins.scss";

.btns {
  @include flex;

  width: 100%;
  gap: 8px;
  margin: 8px 0;
  a {
    @include bcb($black, $primary, $primary);
    padding: 5px 10px;
    flex-grow: 2;
    width: 100%;
  }
  .left {
    text-align: right;
    background: linear-gradient(90deg, $black 50%, $primary 50%);
    background-size: 200% 100%;
    transition: 100ms;
    border-left: none;
    &:hover {
      background: linear-gradient(90deg, $black 49%, $primary 49%);
      background-size: 200% 100%;
      background-position: 100% 0%;
      transition: 200ms;
      color: $black;
    }
  }
  .right {
    text-align: left;
    background: linear-gradient(270deg, $black 50%, $primary 50%);
    background-size: 200% 100%;
    background-position: 100% 0%;
    border-right: none;
    &:hover {
      background: linear-gradient(270deg, $black 49%, $primary 49%);
      background-size: 200% 100%;
      background-position: 0% 0%;
      transition: 200ms;
      color: $black;
    }
  }
  svg {
    padding: 4px;
    border: 2px solid $primary;
    background: linear-gradient($black 50%, $primary 50%);
    background-size: 100% 200%;
    transition: 100ms;
    min-width: 24px;
    flex-grow: 1;
    path {
      fill: $primary;
    }
    &:hover {
      background-position: 0% 100%;
      transition: 200ms;
      path {
        fill: $black;
      }
    }
  }
}
