.background {
  z-index: -1;
  //background-image: url("data:image/svg+xml,<svg fill='#000' xmlns='http://www.w3.org/2000/svg' width='32' viewBox='0 0 499.14 864'><polygon points='499.14 176 499.14 688 277.3 560 277.3 304 499.14 176'/><polygon points='471.4 736 471.4 864 27.73 864 27.73 736 249.57 608 471.4 736'/><polygon points='221.84 304 221.84 560 0 688 0 176 221.84 304'/><polygon points='471.41 0 471.41 128 249.57 256 27.73 128 27.73 0 471.41 0'/></svg>");
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .grid {
    z-index: 0;
    background-color: $bg-black;
    -webkit-mask-image: url("../content/hex_pattern.svg");
    mask-image: url("../content/hex_pattern.svg");
    // background-image: url("../content/hex_pattern.svg");

    width: 100%;
    height: 100%;
  }
  .cursor {
    z-index: -1;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: fixed;
    top: 50%;
    right: 50%;
    @include glow;
    @include pulse;
  }
  .shutdown {
    @include shutdown;
  }
}
