@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

// ALL COLOR VARIBALES ARE SET IN THE DATABSE FILE
:root {
  --black: hsl(220, 6%, 8%);
  --bg-black: hsl(220, 6%, 8.5%);
  --dark-gray: hsl(220, 6%, 12%);
  --white: hsl(220, 6%, 98%);
  --shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.3);

  --pad-1: 5px;
  --pad-2: 10px;
  --pad-3: 15px;
  --pad-4: 20px;
  --pad-5: 30px;
  --pad-6: 45px;
  --pad-7: 60px;
  --pad-8: 90px;
  --pad-9: 120px;
  --pad-10: 180px;
  --pad-11: 240px;
}

$tablet: 640px;
$laptop: 1200px;
$desktop: 1680px;

$black: var(--black);
$bg-black: var(--bg-black);
$dark-gray: var(--dark-gray);
$primary: rgb(255, 163, 0);
$orange: rgb(254, 136, 1);
$yellow: rgb(254, 204, 0);
$gray: #7c7d80;
$white: var(--white);
$shadow: var(--shadow);
$shadow-big: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
$mobile: 992px;

$header-font: "Bebas Neue", cursive;
$main-font: Calibri, sans-serif;

body {
  box-shadow: inset 0px 0px 64px 64px rgba(black, 0.2);
  overflow-x: hidden;
  // background: $black;
  background: $black;
  margin: 0 auto;
}

.App {
  overflow: hidden;
}
h1 {
  font-family: $header-font;
  color: $primary;
  font-size: 64px;
  line-height: 100%;
  margin: 0 auto;
  font-weight: 400;
}

h2 {
  font-family: $header-font;
  color: $white;
  font-size: 32px;
  line-height: 110%;
  margin: 0 auto;
  font-weight: 400;
}

h3,
.btn {
  font-family: $header-font;
  color: $primary;
  font-size: 18px;
  letter-spacing: 0.06em;
  margin: 0 auto;
  font-weight: 400;
}

p {
  font-family: $main-font;
  color: $white;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

div {
  font-family: $main-font;
  font-size: 18px;
  font-weight: 300;
}

ol,
ul {
  padding-inline-start: 12px;
  padding-inline-end: 12px;
}

a,
input,
textarea {
  text-decoration: none;
  font-family: $main-font;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.App {
  width: 100%;
}

.containerApp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 128px 15% 128px 15%;
  gap: 128px;
  text-align: center;
  @media (max-width: $mobile) {
    margin: 92px 15%;
  }
  > * {
    max-width: 1200px;
  }
}

.d-none {
  display: none !important;
}

.v-none {
  visibility: hidden;
}

.o-none {
  opacity: 0;
}
