.lil-container {
  @include flex;
  flex-direction: column;
  width: 100%;
}

.box {
  @include flex(flex-start);
  flex-direction: column;
  width: 100%;
  height: fit-content;
  position: relative;
  gap: 48px;
  padding-top: 64px;
  .title {
    color: $white;
    border-bottom: 2px solid $primary;
    width: fit-content;
    padding: 0 16px;
    margin: 0;
  }
}

#about {
  text-align: start;
  width: fit-content;
  h3 {
    width: 85%;
    text-align: start;
    color: $gray;
    padding: 4px 0;
  }
}

.wrapper {
  padding: 32px;
  background-color: $black;
  width: 100%;
  box-shadow: $shadow;
  border-left: 32px solid $dark-gray;
  border-right: 32px solid $dark-gray;
  h1 {
    margin-bottom: 16px;
    color: $gray;
  }
  @media (max-width: $mobile) {
    padding: 16px;
    border-left: 8px solid $dark-gray;
    border-right: 8px solid $dark-gray;
  }
}
